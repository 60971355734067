<template>
  <v-expansion-panels
    v-model="panel"
    multiple
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-row align="center" no-gutters>
          <v-col cols="auto" class="mr-4">
            <span>Source molecules</span>
          </v-col>
          <v-col cols="auto">
            <v-row v-if="outputs.length >= 1" class="d-flex flex-wrap ga-3 justify-end">
              <v-btn @click="downloadOutputFile(outputs[1])">
                <v-icon dark light>mdi-download</v-icon>
                {{ downloadOutputFileText(outputs[1]) }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <span>
          <v-row v-for="r in nbRows" :key="r">
            <v-col
              v-for="(compound, col) in rowCompounds(r - 1)"
              :key="`${r}_${col}`"
              :cols="v_col"
            >
              <div class="compound-card">
                <span>{{ compound[0] }}</span>
                <div
                  class="structure"
                  v-html="sourceCompounds[(r-1) * nbCols + col] ?
                    sourceCompounds[(r-1) * nbCols + col][2] : ''"
                />

              </div>
            </v-col>
          </v-row>
        </span>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'SourceSmiles',
  components: {
  },
  props: {
    sourceCompounds: {
      type: Array,
      default: () => []
    },
    outputs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      nbRows: 1,
      panel: [0]
    };
  },
  computed: {
    nbCols: function() {
      switch (this.$vuetify.breakpoint.name) {
      case 'xl':
      case 'lg':
        return 4;
      default:
        return 3;
      }
    },
    v_col: function() {
      return Math.floor(12 / this.nbCols);
    }
  },
  watch: {
    sourceCompounds: {
      handler() {
        this.nbRows = Math.ceil(this.sourceCompounds.length / 3);
      }
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.name, parseInt(this.value));
    },
    rowCompounds(row) {
      const first = row * this.nbCols;
      const last = Math.min(
        row * this.nbCols + this.nbCols,
        this.sourceCompounds.length
      );
      return this.sourceCompounds.slice(first, last);
    },
    downloadOutputFileText(file) {
      const fileWithoutExt = file.split('.').slice(0, -1).join('.');
      const convertedName = fileWithoutExt.replace(/_/g, ' ');
      return `DL ${convertedName}`;
    },
    downloadOutputFile(file) {
      event.stopPropagation();
      this.$emit('downloadOutputFile', file);
    }
  }
};
</script>

  <style scoped>
.compound-card {
    margin: 5px;
    padding: 5px;
    border: solid 1px;
}
.compound-card .structure {
    text-align: center;
}
  </style>
