<template>
  <v-container>
    <v-row justify="center" align-content="center" style="min-height: 500px">
      <v-card class="elevation-0">
        <v-row no-gutters>
          <v-col>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-h3 mb-4">
                  DAIIA Platform
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ errorCode }}. {{ messages[errorCode].title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ messages[errorCode].description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn
                class="ml-2"
                depressed
                color="primary"
                :to="{ name: 'ExecutionList' }"
              >
                Go back
              </v-btn>
            </v-card-actions>
          </v-col>
          <v-col>
            <img
              class="daiia-logo"
              src="/favicon.svg"
            >
          </v-col>
        </v-row>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Error',
  props: {
    errorCode: {
      type: [String, Number],
      default: '404'
    }
  },
  data() {
    return {
      messages: {
        404: {
          title: 'Page not found.',
          description: "It seems you're in the wrong page."
        },
        403: {
          title: 'Access denied.',
          description: "It seems you don't have permission to view this page."
        }
      }
    };
  }
};
</script>

<style scoped>
.daiia-logo {
  max-height: 200px;
  max-width: 200px;
}
</style>
