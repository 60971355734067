<template>
  <v-container>
    <h2>Predictions</h2>
    <v-divider />
    <v-row class="mb-5">
      <v-col :cols="cardColumns">
        <DashboardCard
          text="Target Prediction"
          icon="mdi-bullseye-arrow"
          :to="{ name: 'TargetPredictionExecuteView' }"
          color="rgba(216,242,85,0.5)"
        />
      </v-col>
      <v-col :cols="cardColumns">
        <DashboardCard
          text="Pathway Prediction"
          icon="mdi-map-marker-path"
          :to="{ name: 'PathwayPredictionExecuteView' }"
          color="rgba(255,255,128,0.5)"
        />
      </v-col>
    </v-row>

    <h2>Generations</h2>
    <v-divider />
    <v-row class="mb-5">
      <v-col :cols="cardColumns">
        <DashboardCard
          text="Hit Generation"
          icon="mdi-compost"
          :to="{ name: 'HitGenerationExecuteView' }"
          color="rgba(255,202,191,0.5)"
        />
      </v-col>
      <v-col :cols="cardColumns">
        <DashboardCard
          text="Lead Generation"
          icon="mdi-source-branch"
          :to="{ name: 'LeadGenerationExecuteView' }"
          color="rgba(119,217,168,0.5)"
        />
      </v-col>
      <v-col :cols="cardColumns">
        <DashboardCard
          text="Reaction Generation"
          icon="mdi-ray-start-arrow"
          :to="{ name: 'ReactionGenerationExecuteView' }"
          color="rgba(201,172,230,0.5)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardCard from '@/components/DashboardCard';

export default {
  name: 'HomePage',
  components: {
    DashboardCard
  },
  computed: {
    cardColumns: function() {
      return this.$vuetify.breakpoint.name === 'xl' ? 2 : 3;
    }
  },
  mounted() {
    this.checkLoggedIn(this.$session);
  }
};
</script>
