<template>
  <v-card class="text-center" :to="to" :color="color">
    <v-card-text align>
      <v-icon size="55">{{ icon }}</v-icon>
    </v-card-text>
    <v-card-title class="pt-0 justify-center" :style="`font-size: ${fontSize}`">
      {{ text }}
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: ''
    }
  },
  computed: {
    fontSize: function() {
      return ['xl', 'lg'].includes(this.$vuetify.breakpoint.name) ? '1.10rem' : '1rem';
    }
  }
};
</script>
