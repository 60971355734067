<template>
  <v-card>
    <v-card-title>{{ execution['module']['name'] }}</v-card-title>
    <v-card-subtitle>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="mr-4">
          <div>Registration</div>
        </v-col>
        <v-col>
          <div>{{ execution['created_at'] }}</div>
        </v-col>
        <template v-if="execution['status'].toUpperCase() === 'CANCELED'">
          <v-col cols="auto" class="ml-2">
            <v-icon>mdi-dots-horizontal</v-icon>
            <span class="pa-3">Execution canceled.</span>
          </v-col>
        </template>
        <template v-else>
          <template v-if="execution['begin_at']">
            <v-col cols="auto" class="mr-4">
              <div>Start</div>
            </v-col>
            <v-col>
              <div>{{ execution['begin_at'] }}</div>
            </v-col>

            <template v-if="execution['end_at']">
              <v-col cols="auto" class="mr-4">
                <div>End</div>
              </v-col>
              <v-col>
                <div>{{ execution['end_at'] }}</div>
              </v-col>
            </template>
            <template v-else>
              <v-progress-circular
                :size="25"
                indeterminate
              />
              <span class="pa-3">Execution started, waiting for results...</span>
            </template>
          </template>
          <template v-else>
            <v-icon>mdi-dots-horizontal</v-icon>
            <span class="pa-3">In the queue, waiting for execution...</span>
          </template>
        </template>
      </v-row>
    </v-card-subtitle>

    <v-card-actions>
      <template v-if="execution['status'] == 'COMPLETE'">
        <v-row align="center" no-gutters>
          <v-col cols="auto" class="mr-4">
            <span class="ml-2">Results</span>
          </v-col>
          <v-col cols="auto">
            <v-row v-if="outputs.length >= 1">
              <v-btn @click="downloadOutputFile(outputs[0])">
                <v-icon dark light>mdi-download</v-icon>
                {{ downloadOutputFileText(outputs[0]) }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <!-- <div class="col-sm-1">Results</div> -->
      </template>
      <template v-else-if="execution['end_at']">
        <div class="col-sm-1">Status</div>
        <v-col>{{ execution['status'] || 'Unknown' }}</v-col>
      </template>
      <v-spacer />
      <v-col cols="auto">
        <ExecutionInputs :execution-id="execution.id" />
      </v-col>
      <template v-if="execution.cancelable">
        <v-col>
          <CancelExecutionButton
            :execution-id="execution.id"
            @refresh="refresh"
          />
        </v-col>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import CancelExecutionButton from '@/components/CancelExecutionButton';
import ExecutionInputs from '@/components/ExecutionInputs.vue';

export default {
  name: 'YamanishiResultCard',
  components: {
    CancelExecutionButton,
    ExecutionInputs
  },
  props: {
    execution: {
      type: Object,
      required: true
    },
    outputs: {
      type: Array,
      required: true
    }
  },
  methods: {
    downloadOutputFileText(file) {
      const fileWithoutExt = file.split('.').slice(0, -1).join('.');
      const convertedName = fileWithoutExt.replace(/_/g, ' ');
      return `DL ${convertedName}`;
    },
    downloadOutputFile(file) {
      this.$emit('downloadOutputFile', file);
    },
    refresh() {
      this.$emit('refresh');
    }
  }
};
</script>
