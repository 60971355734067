const API_BASE_URI = process.env.VUE_APP_BASE_URL;
const REFRESH_TOKEN_TIME = 1800; // in seconds
const TOKEN_EXPIRED_TIME = 86400; // in seconds
const ITEMS_PER_PAGE_OPTIONS = [20, 50, 100, -1];

export {
  API_BASE_URI,
  REFRESH_TOKEN_TIME,
  TOKEN_EXPIRED_TIME,
  ITEMS_PER_PAGE_OPTIONS
};
