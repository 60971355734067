/* eslint-disable vue/max-len */
import Vue from 'vue';
import { paramsSerializer } from '@/mixins/utils';

const AUTH_URI = '/auth/';
const API_URI = '/api';

const authenticateUser = function(credentials, successCallback, errorCallback) {
  Vue.axios.post(AUTH_URI, credentials)
    .then(successCallback)
    .catch(errorCallback);
};

const refreshToken = function(token, successCallback, errorCallback) {
  Vue.axios.post(`${AUTH_URI}refresh/`, { token })
    .then(successCallback)
    .catch(errorCallback);
};

const getUserInfo = function(successCallback, errorCallback) {
  Vue.axios.get(`${API_URI}/me/`)
    .then(successCallback)
    .catch(function(e) {
      if (typeof errorCallback === 'function') {
        errorCallback(e);
      }
    });
};

const getNonMembers = function(groupId, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/group/${groupId}/nonmembers/`
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getGroupMembers = function(groupId, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/group/${groupId}/members/`
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const addGroupMember = function(groupId, userId, callback, errorCallback) {
  Vue.axios.post(
    `${API_URI}/group/${groupId}/addmember/`,
    { userId }
  ).then(function() {
    callback();
  }).catch(function(e) {
    errorCallback(e);
  });
};

const removeGroupMember = function(groupId, userId, callback, errorCallback) {
  Vue.axios.post(
    `${API_URI}/group/${groupId}/removemember/`,
    { userId }
  ).then(function(response) {
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadMLModules = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/ai/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getMLModuleByName = function(name, callback, errorCallback) {
  const url = `${API_URI}/ai/?name=${name}`;
  Vue.axios.get(url).then(function(response) {
    const module = response.data.length > 0 ? response.data[0] : null;
    callback(module);
  }).catch(function(e) {
    errorCallback(e);
  });
};

// eslint-disable-next-line vue/max-len
const executeMLModule = function(params, parameters, files, callback, errorCallback, inputs = {}) {
  const formData = new FormData();
  for (const name in files) {
    formData.append(name, files[name]);
  }
  formData.append('parameters', JSON.stringify(parameters));
  formData.append('inputs', JSON.stringify(inputs));
  const config = {
    params: params,
    paramsSerializer: paramsSerializer
  };
  if (files.length > 0) {
    config.headers = {
      'content-type': 'multipart/form-data'
    };
  }

  Vue.axios.post(
    `${API_URI}/execute/`, formData, config
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadExecutions = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/executions/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getExecution = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/executions/${id}/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadExecutionCsv = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/executions/${id}/csv/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const loadFilteredExecutionCsv = function(id, filters, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/executions/${id}/csv/`,
    {
      params: { filters: filters },
      paramsSerializer: paramsSerializer
    }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadExecutionFile = function(id, file, filters = []) {
  Vue.axios.get(
    `${API_URI}/executions/${id}/${file}/`,
    {
      params: { filters: filters },
      paramsSerializer: paramsSerializer
    }
  ).then(function(response) {
    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = response.headers['content-disposition'].split('filename=')[1];
    link.click();
  }).catch(function(e) {
    console.log(e);
  });
};

const readDensityFileOfTp = function(id, callback) {
  Vue.axios.get(
    `${API_URI}/executions/${id}/density_info.txt/`
  ).then(function(response) {
    if (typeof response.data !== 'string') {
      return null;
    }
    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    });
    const reader = new FileReader();
    reader.onload = function() {
      const text = reader.result;
      callback(typeof text === 'string' ? text : '');
    };
    reader.readAsText(blob);
  }).catch(function(e) {
    console.log(e);
  });
};

const getHitGenerationResult = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/executions/hit_generation/${id}/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getFilteredHitGenerationResult = function(id, filters, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/executions/hit_generation/${id}/`,
    {
      params: { filters: filters },
      paramsSerializer: paramsSerializer
    }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getHitGenerationSourceSmiles = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/executions/hit_generation/source_smiles/${id}/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getAIModelInputs = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/ai/${id}/input_parameters/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getAIModelOutputs = function(id, callback, errorCallback) {
  Vue.axios.get(`${API_URI}/ai/${id}/output_files/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadFileHelper = function(response, filename, json = false) {
  let content = response.data;
  // For JSON content
  if (json) {
    content = JSON.stringify(content, null, 2);
  }
  const url = new Blob([content], {
    type: response.headers['content-type']
  });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(url);
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
};

const getSvgFromSmiles = async function(smiles, callback, errorCallback) {
  await Vue.axios.get(
    `${API_URI}/utils/?smiles=${smiles}`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const postReaction = function(reaction, callback, errorCallback) {
  Vue.axios.post(
    `${API_URI}/executions/reaction/`,
    { reaction }
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getExecutionVisibility = function(callback, errorCallback) {
  Vue.axios.get(`${API_URI}/const/exec_visibility/`).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const cancelExecution = function(executionId, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/executions/cancel/${executionId}/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const getExecutionInputs = function(id, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/executions/${id}/inputs/`
  ).then(function(response) {
    callback(response.data);
  }).catch(function(e) {
    errorCallback(e);
  });
};

const downloadExecutionInputFile = function(id, fileName, errorCallback) {
  Vue.axios.get(
    `${API_URI}/executions/${id}/${fileName}/?inputFile=true`
  ).then(function(response) {
    const ext = fileName.split('.').pop();
    downloadFileHelper(response, fileName, ext === 'json');
  }).catch(function(e) {
    errorCallback(e);
  });
}

const downloadFile = function(filename, callback, errorCallback) {
  Vue.axios.get(
    `${API_URI}/download_file/?filename=${filename}`,
    { responseType: 'blob' }
  ).then(function(response) {
    const ext = filename.split('.').pop();
    downloadFileHelper(response, filename, ext === 'json');
    callback(response);
  }).catch(function(e) {
    errorCallback(e);
  }
  );
};

const getMolBlockFromSmiles = async function(smiles) {
  return Vue.axios.get(
    `${API_URI}/smiles_to_mol/?smiles=${smiles}`
  ).then(function(response) {
    return response.data;
  }).catch(function(e) {
    return '';
  });
};

export default {
  authenticateUser,
  getUserInfo,
  getNonMembers,
  getGroupMembers,
  addGroupMember,
  removeGroupMember,
  loadMLModules,
  getMLModuleByName,
  executeMLModule,
  loadExecutions,
  getExecution,
  loadExecutionCsv,
  loadFilteredExecutionCsv,
  downloadExecutionFile,
  readDensityFileOfTp,
  getHitGenerationResult,
  getFilteredHitGenerationResult,
  getHitGenerationSourceSmiles,
  getSvgFromSmiles,
  postReaction,
  getExecutionVisibility,
  downloadFile,
  // Below, currently unused, but kept for the future
  getAIModelInputs,
  getAIModelOutputs,
  downloadFileHelper,
  cancelExecution,
  getExecutionInputs,
  downloadExecutionInputFile,
  getMolBlockFromSmiles,
  refreshToken
};
