var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('ModuleInput',{attrs:{"info":{
            description:
              'A compound-induced gene expression signature (CSV format)',
            type: 'file',
          },"filled":"","dense":"","accept":".csv","name":"input"},on:{"setFile":_vm.onSetFile}}),_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[(_vm.hasSampleFile)?_c('sample-download-button',{attrs:{"filename":_vm.config.sample_file}}):_vm._e()],1)],1)],1),_c('h3',[_vm._v("Threshold for selecting regulated genes")]),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"item-text":"text","item-value":"value","items":_vm.settings,"label":"Up-regulated genes: ","outlined":"","dense":""},model:{value:(_vm.upRegulatedGenes),callback:function ($$v) {_vm.upRegulatedGenes=$$v},expression:"upRegulatedGenes"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.upRegulatedGenes==='manual-percent'),expression:"upRegulatedGenes==='manual-percent'"}],attrs:{"cols":"4"}},[_c('ModuleInput',{attrs:{"info":{ description: 'Top (percent)', type: 'float' },"name":"top","dense":""},on:{"changeParameter":_vm.onChange}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.upRegulatedGenes==='manual-value'),expression:"upRegulatedGenes==='manual-value'"}],attrs:{"cols":"4"}},[_c('ModuleInput',{attrs:{"info":{
            description: 'Higher than',
            type: 'float',
          },"name":"higherThan","dense":""},on:{"changeParameter":_vm.onChange}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.upRegulatedGenes==='default'),expression:"upRegulatedGenes==='default'"}],attrs:{"offset":"1","cols":"4"}},[_c('p',{staticClass:"text--secondary"},[_c('span',[_vm._v(" Default: "),_c('b',[_vm._v("Top (percent)")]),_vm._v(" will be set to "),_c('b',[_vm._v("5%")]),_vm._v(". ")])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"item-text":"text","item-value":"value","items":_vm.settings,"label":"Down-regulated genes: ","outlined":"","dense":""},model:{value:(_vm.downRegulatedGenes),callback:function ($$v) {_vm.downRegulatedGenes=$$v},expression:"downRegulatedGenes"}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.downRegulatedGenes==='manual-percent'),expression:"downRegulatedGenes==='manual-percent'"}],attrs:{"cols":"4"}},[_c('ModuleInput',{attrs:{"info":{
            description: 'Bottom (percent)',
            type: 'float',
          },"name":"bottom","dense":""},on:{"changeParameter":_vm.onChange}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.downRegulatedGenes==='manual-value'),expression:"downRegulatedGenes==='manual-value'"}],attrs:{"cols":"4"}},[_c('ModuleInput',{attrs:{"info":{
            description: 'Lower than',
            type: 'float',
          },"name":"lowerThan","dense":""},on:{"changeParameter":_vm.onChange}})],1),_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.downRegulatedGenes==='default'),expression:"downRegulatedGenes==='default'"}],attrs:{"offset":"1","cols":"4"}},[_c('p',{staticClass:"text--secondary"},[_c('span',[_vm._v(" Default: "),_c('b',[_vm._v("Bottom (percent)")]),_vm._v(" will be set to "),_c('b',[_vm._v("5%")]),_vm._v(". ")])])])],1),_c('h3',[_vm._v("Threshold for selecting the number of pathway genes")]),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('ModuleInput',{attrs:{"info":{
            description: 'Pathway Threshold',
            type: 'float',
            default: 10,
          },"name":"num","outlined":""},on:{"changeParameter":_vm.onChange}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":_vm.geneAnnotations,"label":"Gene Annotation","required":"","rules":[v => !!v || 'The type of the input is required']},model:{value:(_vm.geneAnnotation),callback:function ($$v) {_vm.geneAnnotation=$$v},expression:"geneAnnotation"}})],1)],1),_c('ExecuteButton',{attrs:{"disabled":!_vm.canSubmit,"validation-method":_vm.validate,"confirm-loading":_vm.loading},on:{"confirm":_vm.onSubmit}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }